import { Service } from "./scripts/worker/service.js";

const appName = 'Walletbadger', 
version = '1.38.79';

const files = 
[
	'./',
	'./index.html', 
	'./scripts/app.js', 
	'./css/base.css',
	'./scripts/layout/main/app-shell.js'
]; 

const appNameId = appName + '-v' + version;
const service = new Service(appNameId, files); 
